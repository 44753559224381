.h-40 {
    height: 40px !important;
}

.h-45 {
    height: 45px !important;
}

.txt-red{
    color: red;
}

.txt-btn{
    background-color: transparent;
    border: none;
}

.tertiary-btn{
    background-color: transparent;
    text-decoration: underline;
    border: none;
}

.txt-underline{
    text-decoration: underline;
    cursor: pointer;
}

.light-bg-btn{
    background-color: rgb(243, 243, 243);
    border: none;
}

.disabled-btn{
    opacity: 0.8;
}

.close-btn{
    width: 30px;
    height: 100%;
    background-color: transparent;
    color: rgb(112, 112, 112);
    border: none;
}

.undo-btn{
    width: 30px;
    height: 100%;
    background-color: red;
    color: rgb(255, 255, 255);
    border: none;
}

.close-btn:hover{
    background-color: rgb(243, 243, 243);
}

.txt-xsm{
    font-size: 12px;
}